import { EventSourceParserStream } from 'eventsource-parser/stream';

self.onmessage = async function (event) {
  const { readableStream } = event.data;

  try {
    const reader = readableStream
      .pipeThrough(new TextDecoderStream())
      .pipeThrough(new EventSourceParserStream())
      .getReader();

    for (;;) {
      const { done, value: parsedEvent } = await reader.read();
      if (done) {
        break;
      }

      self.postMessage({ event: parsedEvent }); // pass parsed events back to the main thread
    }

    self.postMessage({ done: true });
  } catch (error) {
    self.postMessage({ error: error.message });
  }
};
